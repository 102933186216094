import * as React from "react";
import { useEffect } from "react";
import { navigate, withPrefix } from "gatsby";
import Footer1 from "../components/footer-1";
import Header from "../components/header";
import { isLoggedIn } from "../services/auth";
import "../components/css/thank-you.css";

export default function ThankYou() {
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
    }

    localStorage.removeItem("getPaymentId_response");
    localStorage.removeItem("getOrderEst_response");
    localStorage.removeItem("selectedArray");
    localStorage.removeItem("final-products");
    localStorage.removeItem("order-cost");
    localStorage.removeItem("printfulOrder_payload");
  }, []);

  const backHome = () => {
    navigate("/");
  };

  return (
    <>
      <div className="container-main" id="page">
        <Header></Header>

        <main className="content-main">
          <div className="thankyou-box">
            <div className="container">
              <div className="box">
                <div className="step-cricle">
                  <ul>
                    <li>Step</li>
                    <li>Step</li>
                    <li className="active">Step</li>
                  </ul>
                </div>

                <div className="figure">
                  <img
                    src={withPrefix("assets/img/thankyou-art.png")}
                    alt="thankyou-art"
                  />
                </div>

                <h1>Thank you!</h1>
                <p>
                  We've sent the link to your inbox please verify your order
                  details!
                </p>
                <div className="btn-out">
                  <a onClick={() => backHome()} href="#" className="btn">
                    Back Home
                  </a>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer1></Footer1>
      </div>
    </>
  );
}
